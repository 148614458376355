define("ember-svg-jar/inlined/smile-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#757575\" d=\"M13.335 21.333c0-2.455 1.545-4 4-4s4 1.545 4 4h-8zM20.001 13.333a2.667 2.667 0 11-5.334 0 2.667 2.667 0 015.334 0z\"/><path fill=\"#757575\" d=\"M25.335 2.667h-16a2.669 2.669 0 00-2.667 2.667v2.667H4.001v2.667h2.667v4H4.001v2.667h2.667v4H4.001v2.667h2.667v2.667a2.67 2.67 0 002.667 2.667h16a2.67 2.67 0 002.667-2.667V5.336a2.669 2.669 0 00-2.667-2.667zm-16 24V24h1.333v-2.667H9.335v-4h1.333v-2.667H9.335v-4h1.333V7.999H9.335V5.332h16l.001 21.333H9.335z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});