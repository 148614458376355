define("ember-svg-jar/inlined/smile-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M4 1h13v12H4z\"/><path d=\"M18.093 19.048H2.855A1.907 1.907 0 01.95 17.143V8.57a.952.952 0 011.562-.731l5.45 4.541h5.025l5.45-4.542a.953.953 0 011.56.731v8.572a1.905 1.905 0 01-1.904 1.906z\" fill=\"#47C1BF\"/><path fill=\"#47C1BF\" d=\"M18.093 9l-1.904 1V1.905H4.76V10L2.855 8.5V0h15.238z\"/><path fill=\"#47C1BF\" d=\"M6.665 3.81h7.619v1.904h-7.62zM6.665 6.667h5.714V8.57H6.665z\"/><path d=\"M2.855 10.605v6.538h15.24v-6.538l-4.153 3.46a.958.958 0 01-.61.22H7.616a.958.958 0 01-.61-.22l-4.152-3.46z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});