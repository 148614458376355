define("ember-svg-jar/inlined/polaris-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"10\" cy=\"10\" r=\"9\" fill=\"#FFF\"/><path d=\"M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-4a1 1 0 100 2 1 1 0 100-2m0-10C8.346 4 7 5.346 7 7a1 1 0 102 0 1.001 1.001 0 111.591.808C9.58 8.548 9 9.616 9 10.737V11a1 1 0 102 0v-.263c0-.653.484-1.105.773-1.317A3.013 3.013 0 0013 7c0-1.654-1.346-3-3-3\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
});