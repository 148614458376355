define("ember-svg-jar/inlined/smile-circle-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.4A6.407 6.407 0 013.6 10c0-3.529 2.871-6.4 6.4-6.4 3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4zm0-5.6a.8.8 0 00.8-.8V6.8a.8.8 0 00-1.6 0V10a.8.8 0 00.8.8zm0 1.52a.88.88 0 100 1.76.88.88 0 000-1.76z\" fill=\"#637381\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});