define("ember-svg-jar/inlined/polaris-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 11a1 1 0 011 1c0 1.654-1.346 3-3 3H5.414l1.293 1.293a.999.999 0 11-1.414 1.414l-3-3a.999.999 0 010-1.414l3-3a.999.999 0 111.414 1.414L5.414 13H15c.552 0 1-.449 1-1a1 1 0 011-1zM3 9a1 1 0 01-1-1c0-1.654 1.346-3 3-3h9.586l-1.293-1.293a.999.999 0 111.414-1.414l3 3a.999.999 0 010 1.414l-3 3a.997.997 0 01-1.414 0 .999.999 0 010-1.414L14.586 7H5c-.552 0-1 .449-1 1a1 1 0 01-1 1z\" fill=\"#31373D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});