define("ember-svg-jar/inlined/polaris-avatar-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#ffe0c3\" d=\"M0-.01h100v100H0z\"/><path fill=\"#5d6cc1\" d=\"M0 0h69.02v100H0z\"/><path d=\"M69.02 0l24.03 61.69H69.02V0z\" fill=\"#ff967d\"/><path fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" stroke-width=\"5\" d=\"M30.69 31.91v-3c0-4.78 3.46-8.65 8-8.65s8 3.87 8 8.65v3\"/><path fill=\"#ebedf1\" d=\"M12.76 56.06a13.36 13.36 0 1126.72 0\" opacity=\".2\"/><path d=\"M80 61.46l-29.34.23v-7.33c0-6.28 4.07-11.36 10.34-11.44l19-.14z\" fill=\"#ff967d\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});