define("ember-svg-jar/inlined/smile-bigcommerce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#4680F9\" cx=\"40\" cy=\"40\" r=\"40\"/><path d=\"M40.921 42.058c-.15 0-.257-.106-.278-.253v-3.118c0-.127.128-.253.278-.253h4.522c1.286 0 2.1.758 2.1 1.812 0 1.116-.814 1.812-2.1 1.812H40.92zm0 7.184c-.15 0-.257-.105-.278-.274v-3.434c0-.126.128-.253.278-.253h4.672c1.478 0 2.293.885 2.293 1.98 0 1.265-.857 1.981-2.293 1.981H40.92zm16.543-27.158c.172-.168.472-.042.407.19v34.721a.236.236 0 01-.235.232H22.343c-.279 0-.407-.337-.214-.526l14.678-14.37v9.924c0 .126.129.253.279.253h9.471c3.45 0 5.229-2.086 5.229-4.699 0-1.854-1.093-3.434-2.636-4.024-.236-.084-.236-.379-.021-.463 1.328-.59 2.571-1.918 2.571-3.73 0-2.296-2.014-4.403-5.421-4.403h-2.186l13.371-13.105z\" fill=\"#FFF\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});