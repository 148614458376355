define("ember-svg-jar/inlined/smile-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#919EAB\" fill-rule=\"evenodd\"><path d=\"M9.291 0H4.156C3.392 0 2.77.622 2.77 1.385v9.697c0 .764.62 1.386 1.385 1.386h6.926c.764 0 1.386-.622 1.386-1.386V3.176L9.29 0zm1.792 11.082H4.156V1.385h4.156v2.77h2.77v6.927z\"/><path d=\"M1.247 3.394H0v9.974c0 .688.56 1.247 1.247 1.247h7.48v-1.247h-7.48V3.394z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "15",
      "viewBox": "0 0 13 15"
    }
  };
});