define("ember-svg-jar/inlined/polaris-avatar-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#5d6cc1\" d=\"M-.02 0h100v100h-100z\"/><path fill=\"#6dcace\" d=\"M.39 0h69.02v100H.39z\"/><path fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" stroke-width=\"5\" d=\"M24.18 31.21v3.47A10.43 10.43 0 0034.4 45.21a10.43 10.43 0 0010.22-10.53v-3.47\"/><path fill=\"#ebedf1\" d=\"M20.11 49.07a16.22 16.22 0 110 32.44\" opacity=\".2\"/><path d=\"M69.44 18.83L90 71H69.44V18.83z\" fill=\"#fff\"/><path d=\"M57.59 71a6 6 0 0112 0z\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});