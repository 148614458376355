define("ember-svg-jar/inlined/polaris-external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 2a1 1 0 011 1v4a1 1 0 11-2 0V5.414l-7.293 7.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414L14.586 4H13a1 1 0 110-2h4zm-4 9a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1V7a1 1 0 011-1h5a1 1 0 110 2H4v8h8v-4a1 1 0 011-1z\" fill=\"#31373D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});