define("ember-svg-jar/inlined/smile-icon-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#212B36\" fill-rule=\"evenodd\"><ellipse cx=\"2.2\" cy=\"2.133\" rx=\"2.2\" ry=\"2.133\"/><ellipse cx=\"8.8\" cy=\"2.133\" rx=\"2.2\" ry=\"2.133\"/><ellipse cx=\"2.2\" cy=\"8\" rx=\"2.2\" ry=\"2.133\"/><ellipse cx=\"8.8\" cy=\"8\" rx=\"2.2\" ry=\"2.133\"/><ellipse cx=\"2.2\" cy=\"13.867\" rx=\"2.2\" ry=\"2.133\"/><ellipse cx=\"8.8\" cy=\"13.867\" rx=\"2.2\" ry=\"2.133\"/></g>",
    "attrs": {
      "width": "11",
      "height": "16",
      "viewBox": "0 0 11 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});