define("ember-svg-jar/inlined/smile-partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M17.59 7.74l1.001-1.036-4.297-4.15-1 1.036 4.297 4.15zM4.7 9.838l6.886-5.555.17-.177a.733.733 0 01-.036-1.037l2-2.071a.734.734 0 011.037 0l5.371 5.187c.297.287.314.751.037 1.037l-2 2.072a.733.733 0 01-1.037-.001l-.28-.27-.619 1.399a.7.7 0 01-.147.229l-5.733 4.202a.727.727 0 01-.933.084L4.842 10.96a.765.765 0 01-.333-.537.711.711 0 01.191-.584z\" fill=\"#757575\" fill-rule=\"nonzero\"/><path d=\"M3.075 7.74l-1-1.036 4.296-4.15 1 1.036-4.296 4.15zm12.89 2.099L9.08 4.284l-.171-.177a.733.733 0 00.037-1.037L6.946 1a.734.734 0 00-1.038 0L.538 6.187A.734.734 0 00.5 7.223l2 2.072a.733.733 0 001.038-.001l.28-.27 1.036 2.631a.7.7 0 00.147.23l4.585 3.41c.242.25.635.285.933.083l5.305-4.418a.765.765 0 00.332-.537.711.711 0 00-.19-.584z\" fill=\"#757575\" fill-rule=\"nonzero\"/><path fill=\"#F4F6F8\" fill-rule=\"nonzero\" d=\"M10.044 13.94l-3.747-2.826-1.196-3.041 3.204-2.996 6.18 5.183z\"/><path d=\"M12.4 5.106L7.58 9.442a.05.05 0 00-.003.072l.293.297.304.31a.05.05 0 00.058.01l5.176-2.555a.05.05 0 01.057.01L15.081 9.2a.05.05 0 00.074-.003l.576-.697a.05.05 0 00-.004-.067L12.4 5.106z\" fill=\"#F4F6F8\" fill-rule=\"nonzero\"/><path d=\"M13.29 8.47l-4.726 2.333a.8.8 0 01-.924-.156l-.597-.606-.025-.027a.8.8 0 01.06-1.13l5.349-4.811 3.83 3.83a.8.8 0 01.052 1.076l-.576.696c-.024.03-.024.03-.05.056a.8.8 0 01-1.132 0l-1.261-1.26z\" stroke=\"#757575\" stroke-width=\"1.5\"/></g>",
    "attrs": {
      "width": "21",
      "height": "16",
      "viewBox": "0 0 21 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});