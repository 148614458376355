define("ember-svg-jar/inlined/smile-platform-icons-shopify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"nonzero\" fill=\"none\"><path fill=\"#95BF47\" d=\"M12.205 3.08a.148.148 0 00-.14-.127c-.063 0-1.272-.09-1.272-.09s-.84-.84-.942-.928c-.09-.09-.28-.064-.344-.039-.012 0-.19.051-.47.14C8.756 1.222 8.26.471 7.381.471h-.077C7.051.14 6.745 0 6.478 0 4.43 0 3.45 2.558 3.144 3.856c-.79.242-1.362.42-1.426.446-.445.14-.458.153-.509.573C1.16 5.18 0 14.153 0 14.153l9.036 1.692 4.9-1.056S12.218 3.17 12.206 3.08zm-3.678-.89c-.229.075-.483.152-.763.24v-.165c0-.509-.077-.916-.179-1.234.459.05.751.56.942 1.158zM7.025 1.12c.128.318.204.764.204 1.375v.089c-.496.152-1.044.318-1.578.483.305-1.158.878-1.73 1.374-1.947zm-.61-.573c.089 0 .178.026.267.09-.65.305-1.362 1.081-1.655 2.634-.432.14-.852.267-1.247.382C4.124 2.469 4.95.547 6.415.547z\"/><path fill=\"#5E8E3E\" d=\"M12.065 2.953c-.063 0-1.272-.09-1.272-.09s-.84-.84-.942-.928a.242.242 0 00-.127-.064l-.688 13.974 4.9-1.056-1.73-11.696c-.013-.09-.09-.128-.14-.14z\"/><path fill=\"#FFF\" d=\"M7.37 5.664l-.6 1.794s-.534-.28-1.17-.28c-.955 0-.993.598-.993.751 0 .815 2.138 1.133 2.138 3.055 0 1.514-.954 2.481-2.252 2.481-1.553 0-2.342-.967-2.342-.967l.42-1.374s.814.7 1.502.7a.614.614 0 00.636-.611c0-1.07-1.756-1.12-1.756-2.877 0-1.476 1.056-2.914 3.207-2.914.802 0 1.21.242 1.21.242z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
});