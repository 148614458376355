define("ember-svg-jar/inlined/smile-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#757575\" d=\"M16.003 16.008a5.339 5.339 0 01-5.333-5.333c0-2.94 2.392-5.333 5.333-5.333s5.333 2.393 5.333 5.333a5.339 5.339 0 01-5.333 5.333zm0-8a2.669 2.669 0 00-2.667 2.667c0 1.471 1.196 2.667 2.667 2.667s2.667-1.196 2.667-2.667a2.669 2.669 0 00-2.667-2.667zM26.669 26.675H5.336v-1.333c0-4.711 4.387-8 10.667-8s10.667 3.289 10.667 8v1.333zM8.215 24.008h15.577c-.797-2.411-3.777-4-7.788-4s-6.992 1.589-7.789 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});