define("ember-svg-jar/inlined/polaris-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 6H4a1 1 0 100 2h1v9a1 1 0 001 1h8a1 1 0 001-1V8h1a1 1 0 100-2zM9 4a1 1 0 110-2h2a1 1 0 110 2H9zm2 12h2V8h-2v8zm-4 0h2V8H7v8z\" fill=\"#31373D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});