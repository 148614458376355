define("ember-svg-jar/inlined/smile-icon-enable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.6 7.2H3.2V4c0-1.323 1.077-2.4 2.4-2.4S8 2.677 8 4v.8h1.6V4c0-2.206-1.794-4-4-4s-4 1.794-4 4v3.2A1.6 1.6 0 000 8.8v5.6c0 .882.717 1.6 1.6 1.6h8c.883 0 1.6-.718 1.6-1.6V8.8a1.6 1.6 0 00-1.6-1.6zm-8 7.2V8.8h8l.002 5.6H1.6zm5.2-2.8c0 .8-.4 1.2-1.2 1.2-.8 0-1.2-.4-1.2-1.2 0-.8.4-1.2 1.2-1.2.8 0 1.2.4 1.2 1.2z\" fill=\"#637381\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "12",
      "height": "16",
      "viewBox": "0 0 12 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});