define("ember-svg-jar/inlined/polaris-avatar-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M-.02-.01h100v100h-100z\" fill=\"#ffe0c3\"/><path fill=\"#ff967d\" d=\"M0 0h69.41v100H0z\"/><path d=\"M69.92 0v44.32L51.34 55v45H100V0z\" fill=\"#ffe0c3\"/><path fill=\"#32cac6\" d=\"M39.32 76a11.85 11.85 0 0012 11.62V76\"/><path fill=\"#009796\" d=\"M39.32 76a12 12 0 0112-11.82V76\"/><path fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" stroke-width=\"5\" d=\"M43.74 19.83a12.82 12.82 0 11-25.64 0\"/><path fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" stroke-width=\"4\" d=\"M27.39 31.6l-1.58 5.96m9.37-5.72l2.55 5.47m4.26-9.85l3.53 4.5m-25.43-4.5l-3.53 4.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});