define("ember-svg-jar/inlined/smile-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFC828\" cx=\"40\" cy=\"40\" r=\"40\"/><path d=\"M22 27.53A5.531 5.531 0 0127.53 22h24.94A5.531 5.531 0 0158 27.53v24.94A5.531 5.531 0 0152.47 58H27.53A5.531 5.531 0 0122 52.47V27.53zm24.236 9.022h-1.085a.759.759 0 00-.558.234.735.735 0 00-.236.502c-.003.117-.02.803-.058 1.236-.117 1.256-.533 2.266-1.248 3.03-.708.76-1.71 1.142-3.006 1.148-1.297-.006-2.3-.387-3.008-1.148-.715-.764-1.13-1.774-1.248-3.03-.039-.433-.055-1.12-.058-1.237a.735.735 0 00-.236-.501.759.759 0 00-.558-.234h-1.085a.761.761 0 00-.559.234c-.14.142-.234.283-.234.53 0 0 .038 1.128.059 1.354.176 2.002.865 3.596 2.07 4.783 1.192 1.176 2.803 1.77 4.832 1.78h.048c2.03-.01 3.64-.604 4.832-1.78 1.205-1.187 1.894-2.781 2.07-4.783.02-.226.059-1.354.059-1.354 0-.247-.093-.388-.234-.53a.761.761 0 00-.559-.234z\" fill=\"#FFF\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});