define("ember-svg-jar/inlined/polaris-avatar-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#ffedb9\" d=\"M0 0h100v100H0z\"/><path d=\"M64.63 17.33a17 17 0 015 29.72 16.75 16.75 0 01-5 2.62\" fill=\"none\" stroke=\"#fff\" stroke-miterlimit=\"10\" stroke-width=\"5\"/><path fill=\"#ffc04d\" d=\"M0 0h69.02v100H0z\"/><circle cx=\"45.11\" cy=\"33.49\" r=\"16.98\" fill=\"none\" stroke=\"#fff\" stroke-miterlimit=\"10\" stroke-width=\"5\" transform=\"rotate(-37.02 45.124 33.493)\"/><path fill=\"#5d6cc1\" d=\"M69.02 34.48l19.47 38.74-19.47 1.53V34.48z\"/><path d=\"M61.6 33.67a10.17 10.17 0 0115.4.08\" fill=\"none\" stroke=\"#fff\" stroke-miterlimit=\"10\" stroke-width=\"5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});