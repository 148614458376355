define("ember-svg-jar/inlined/smile-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g fill=\"#637381\"><path d=\"M6.9 16h7.7c.772 0 1.4-.628 1.4-1.4V3.4c0-.771-.628-1.4-1.4-1.4H6.9c-.772 0-1.4.629-1.4 1.4v2.1h1.4V3.4h7.7v11.2H6.9v-2.1H5.5v2.1c0 .772.628 1.4 1.4 1.4z\"/><path d=\"M2 8.406h6.51L7.143 7.042l.842-.842 2.8 2.795-2.8 2.805-.842-.84 1.362-1.365H2z\"/></g></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});