define("ember-svg-jar/inlined/smile-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#757575\" d=\"M18 22a2 2 0 11-3.999.001A2 2 0 0118 22z\"/><path fill=\"#757575\" d=\"M22.667 14.667V9.334c0-3.676-2.991-6.667-6.667-6.667S9.333 5.658 9.333 9.334v5.333a2.668 2.668 0 00-2.667 2.667v9.333a2.669 2.669 0 002.667 2.667h13.333a2.669 2.669 0 002.667-2.667v-9.333a2.668 2.668 0 00-2.667-2.667zM12 9.333c0-2.205 1.795-4 4-4s4 1.795 4 4v5.333h-8V9.333zM9.333 26.667v-9.333h13.333l.003 9.333H9.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});