define("ember-svg-jar/inlined/polaris-avatar-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#8dc958\" d=\"M0 0h70.02v100H0z\"/><path d=\"M69.92 0v56.32L49 67l-.3 25.07V100H100V0z\" fill=\"#2db167\"/><path fill=\"#245b48\" d=\"M59.27 58.29a5.22 5.22 0 00-9.43 4.48\"/><path d=\"M27.12 9.33h44.53v2.19H27.12zm-12.41 9.49h56.94v2.19H14.71z\" fill=\"#2db167\"/><circle cx=\"19.66\" cy=\"44.8\" r=\"11.22\" fill=\"#ebedf1\" opacity=\".2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});