define("ember-svg-jar/inlined/polaris-avatar-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 0h100v100.48H0z\" fill=\"#8dc958\"/><path fill=\"#2db167\" d=\"M83 68v32l-14-.16v.16H0V0h69v68h14z\"/><path d=\"M69.29 41.69H20.66s-.09-3-.17 7.15 7 18.51 17.35 18.59l31.45.57z\" fill=\"#8dc958\"/><path d=\"M68.7 12.4l11.54 29.29H68.7V12.4z\" fill=\"#fff\"/><path d=\"M62.22 41.69a3.34 3.34 0 116.69 0z\" fill=\"#fff\"/><path fill=\"#245b48\" d=\"M41.45 18.06a2.57 2.57 0 00-5.14 0M33.33 24a2.57 2.57 0 10-5.14 0m20.36 2.58a2.57 2.57 0 10-5.14 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});