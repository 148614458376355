define("ember-svg-jar/inlined/smile-announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M7.618 4.762h1.905a3.813 3.813 0 00-3.81-3.81v1.905c1.05 0 1.905.854 1.905 1.905M16.19 11.429v1.904c1.05 0 1.904.855 1.904 1.905H20a3.813 3.813 0 00-3.81-3.81M14.256 1.184L12.408.722l-.952 3.81 1.848.462zM20.23 8.544l-.462-1.848-3.81.952.462 1.848zM7.848 5.897c-.722 0-1.168.293-1.414.539-.223.224-5.277 11.943-5.404 12.231a.951.951 0 001.254 1.254c.292-.127 12.007-5.179 12.232-5.403 1.388-1.389-.074-4.116-2.02-6.062-1.579-1.578-3.36-2.56-4.648-2.56z\" fill=\"#74828F\"/><path d=\"M6.74 10.335a11.601 11.601 0 001.715 2.162 11.336 11.336 0 002.169 1.711l-6.878 2.998 2.995-6.871zm6.41 2.772c-.715.31-2.782-1.391-3.348-1.958-.574-.572-2.263-2.644-1.957-3.347h.003c.645 0 1.987.687 3.3 2 1.533 1.534 2.017 2.865 2.002 3.305z\" fill=\"#FFF\"/><path fill=\"#74828F\" d=\"M18.767 3.53l-1.346-1.346-1.905 1.905 1.347 1.346z\"/></g>",
    "attrs": {
      "width": "21",
      "height": "20",
      "viewBox": "0 0 21 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});