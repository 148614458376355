define("ember-svg-jar/inlined/smile-pricing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.42.12a.8.8 0 00-.779-.036L8.857 1.478 6.843.134a.8.8 0 00-.887 0L3.942 1.478 1.158.084A.8.8 0 000 .8v14.4a.803.803 0 00.8.8.794.794 0 00.358-.085l2.784-1.392 2.014 1.342a.8.8 0 00.887 0l2.014-1.342 2.784 1.392A.8.8 0 0012.8 15.2V.8a.799.799 0 00-.38-.68zM11.2 13.905l-2.043-1.021a.8.8 0 00-.801.05L6.4 14.238l-1.956-1.304a.803.803 0 00-.802-.05L1.6 13.905V2.095l2.042 1.02a.8.8 0 00.802-.05L6.4 1.762l1.956 1.304a.8.8 0 00.801.05l2.043-1.02v11.81zM7.2 4H5.6v.84C4.688 5.026 4 5.834 4 6.8c0 1.102.897 2 2 2h.8a.4.4 0 010 .8H4v1.6h1.6v.8h1.6v-.84A2.003 2.003 0 008.8 9.2c0-1.103-.897-2-2-2H6a.4.4 0 010-.8h2.8V4.8H7.2V4z\" fill=\"#637381\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "13",
      "height": "16",
      "viewBox": "0 0 13 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});