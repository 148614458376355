define("ember-svg-jar/inlined/polaris-circle-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFF\" cx=\"10\" cy=\"10\" r=\"9\"/><path d=\"M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m0-13a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1m0 8a1 1 0 100 2 1 1 0 000-2\" fill=\"#919EAB\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});